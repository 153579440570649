import { makeStyles, createStyles } from '@material-ui/core/styles'
import Colors from 'spartacus/styles/colors'
import { Props } from '.'

interface Classes {
  [key: string]: string
}

type Width = Props['width']

const getWidth = ({ width }: { width: Width }): string => (width === 'full' ? '100%' : 'auto')
const getMinWidth = ({ width }: { width: Width }): string =>
  width === 'default' ? '225px' : 'auto'

const baseButtonStyles = createStyles({
  root: {
    borderRadius: '100px',
    fontFamily: "'Mabry', sans-serif",
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '28px',
    textTransform: 'none',
    minHeight: '50px',
    padding: '0 24px',
  },
})

const bigButtonStyles = createStyles({
  root: {
    ...baseButtonStyles.root,
    fontSize: '20px',
    minHeight: '60px',
  },
})

const usePrimaryButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    color: Colors.UI.White,
    background: Colors.Brand.Primary1,
    '&:hover': {
      background: Colors.UI.HoverPrimary,
    },
    '&$disabled': {
      background: Colors.UI.Disabled,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const usePrimaryBigButtonStyles = makeStyles({
  root: {
    ...bigButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    color: Colors.UI.White,
    background: Colors.Brand.Primary1,
    '&:hover': {
      background: Colors.UI.HoverPrimary,
    },
    '&$disabled': {
      background: Colors.UI.Disabled,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const usePrimaryLightButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    color: Colors.Type.Primary,
    background: Colors.Brand.Primary3,
    '&:hover': {
      background: Colors.util.lighten(Colors.Brand.Primary3, 0.5),
    },
    '&$disabled': {
      background: Colors.UI.Disabled,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const usePrimaryDangerButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    color: Colors.UI.White,
    background: Colors.Brand.Secondary5,
    '&:hover': {
      background: Colors.util.lighten(Colors.Brand.Secondary5, 0.5),
    },
    '&$disabled': {
      background: Colors.UI.Disabled,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const useSecondaryButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    color: Colors.Type.Primary,
    background: 'transparent',
    border: `1px solid ${Colors.Type.Primary}`,
    '&:hover': {
      border: `1px solid ${Colors.UI.HoverPrimary}`,
      color: Colors.UI.HoverPrimary,
    },
    '&$disabled': {
      border: `1px solid ${Colors.UI.Disabled}`,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const useSecondaryLilacButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    color: Colors.UI.TemporaryLilac,
    background: 'transparent',
    border: `1px solid ${Colors.UI.TemporaryLilac}`,
    '&:hover': {
      border: `1px solid ${Colors.util.lighten(Colors.UI.TemporaryLilac, 0.2)}`,
      color: Colors.util.lighten(Colors.UI.TemporaryLilac, 0.2),
    },
    '&$disabled': {
      border: `1px solid ${Colors.UI.Disabled}`,
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const useTextButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    color: Colors.Type.Primary,
    background: 'transparent',
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.UI.HoverPrimary,
      textDecoration: 'underline',
    },
    '&$disabled': {
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const useTextLightButtonStyles = makeStyles({
  root: {
    ...baseButtonStyles.root,
    width: getWidth,
    minWidth: getMinWidth,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    color: Colors.UI.White,
    background: 'transparent',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&$disabled': {
      color: Colors.Type.Secondary,
    },
  },
  disabled: {},
})

const usePrimaryLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const usePrimaryLightLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const usePrimaryDangerLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const usePrimaryBigLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const useSecondaryLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const useSecondaryLilacLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

const useTextLoadingIconStyles = makeStyles({
  root: {
    color: Colors.Type.Secondary,
    marginRight: 8,
  },
})

export const getButtonClasses = (variant: Props['variant'], width: Width): Classes => {
  const primaryButtonClasses = usePrimaryButtonStyles({ width })
  const primaryLightButtonClasses = usePrimaryLightButtonStyles({ width })
  const primaryDangerButtonClasses = usePrimaryDangerButtonStyles({ width })
  const primaryBigButtonClasses = usePrimaryBigButtonStyles({ width })
  const secondaryButtonClasses = useSecondaryButtonStyles({ width })
  const secondaryLilacButtonClasses = useSecondaryLilacButtonStyles({ width })
  const textButtonClasses = useTextButtonStyles({ width })
  const textLightButtonClasses = useTextLightButtonStyles({ width })

  if (variant === 'primary-light') return primaryLightButtonClasses
  if (variant === 'primary-danger') return primaryDangerButtonClasses
  if (variant === 'primary-big') return primaryBigButtonClasses
  if (variant === 'secondary') return secondaryButtonClasses
  if (variant === 'secondary-lilac') return secondaryLilacButtonClasses
  if (variant === 'text') return textButtonClasses
  if (variant === 'text-light') return textLightButtonClasses

  return primaryButtonClasses
}

export const getLoadingIconClasses = (variant: Props['variant']): Classes => {
  const primaryLoadingIconClasses = usePrimaryLoadingIconStyles()
  const primaryLightLoadingIconClasses = usePrimaryLightLoadingIconStyles()
  const primaryDangerLoadingIconClasses = usePrimaryDangerLoadingIconStyles()
  const primaryBigLoadingIconClasses = usePrimaryBigLoadingIconStyles()
  const secondaryLoadingIconClasses = useSecondaryLoadingIconStyles()
  const secondaryLilacLoadingIconClasses = useSecondaryLilacLoadingIconStyles()
  const textLoadingIconClasses = useTextLoadingIconStyles()

  if (variant === 'primary-light') return primaryLightLoadingIconClasses
  if (variant === 'primary-danger') return primaryDangerLoadingIconClasses
  if (variant === 'primary-big') return primaryBigLoadingIconClasses
  if (variant === 'secondary') return secondaryLoadingIconClasses
  if (variant === 'secondary-lilac') return secondaryLilacLoadingIconClasses
  if (variant === 'text') return textLoadingIconClasses

  return primaryLoadingIconClasses
}
