import { Button as MuiButton, CircularProgress as MuiCircularProgress } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import React from 'react'
import * as S from './style'

export type Variant =
  | 'primary'
  | 'primary-light'
  | 'primary-danger'
  | 'primary-big'
  | 'secondary'
  | 'secondary-lilac'
  | 'text'
  | 'text-light'

export interface Props extends Omit<ButtonProps, 'variant'> {
  children?: React.ReactNode
  variant?: Variant
  width?: 'default' | 'auto' | 'full'
  disabled?: boolean
  loading?: boolean
}

const Button = React.forwardRef(
  (
    {
      children,
      variant = 'primary',
      width = 'default',
      disabled = false,
      loading = false,
      ...rest
    }: Props,
    ref,
  ): JSX.Element => {
    const buttonClasses = S.getButtonClasses(variant, width)
    const loadingIconClasses = S.getLoadingIconClasses(variant)

    return (
      <MuiButton
        ref={ref as React.RefObject<HTMLButtonElement>}
        classes={buttonClasses}
        disabled={disabled || loading}
        startIcon={loading && <MuiCircularProgress classes={loadingIconClasses} size={20} />}
        {...rest}
      >
        {children}
      </MuiButton>
    )
  },
)

export default Button
